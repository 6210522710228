'use client';

import { mockGirls } from '@/mock/girls';
import { AnimationPlaybackControls, motion, useInView, useScroll, useTransform } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Fragment, useEffect, useRef, useState } from 'react';
import MaxWidthWrapper from '../MaxWidthWrapper';
import { useTranslations } from 'next-intl';
import { useQuery } from '@tanstack/react-query';
import { getTopCreators } from '@/actions/girl.actions';
import { replaceApiUrl } from '@/lib/utils';
import { Link } from '@/i18n/routing';
const TopGirlsSection = () => {
  const t = useTranslations('landingPage.popularGirls');
  const router = useRouter();
  const scrollRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [girlsWidth, setGirlsWidth] = useState<number>(0);
  const topGirlsQuery = useQuery({
    queryKey: ['topGirls'],
    queryFn: () => getTopCreators()
  });
  const {
    scrollYProgress
  } = useScroll({
    target: scrollRef
  });
  const isInView = useInView(scrollRef, {
    once: true
  });
  const scrollX = useTransform(scrollYProgress, [0, 1], [-girlsWidth, 0]);
  const [animateControls, setAnimateControls] = useState<AnimationPlaybackControls | null>(null);
  useEffect(() => {
    if (isInView) {
      if (wrapperRef.current) {
        setGirlsWidth(wrapperRef.current.scrollWidth - wrapperRef.current.clientWidth);
      }
    }
  }, [mockGirls, isInView, topGirlsQuery.data]);
  return <div className="mb-20 space-y-10 overflow-hidden py-9" ref={scrollRef} data-sentry-component="TopGirlsSection" data-sentry-source-file="TopGirls.section.tsx">
            <h2 className="text-center text-4xl font-bold">{t('title')}</h2>
            {<MaxWidthWrapper>
                    <div className="overflow-hidden">
                        <motion.div ref={wrapperRef} className="flex w-auto" onDragStart={() => {
          animateControls?.stop();
          setAnimateControls(null);
        }} onHoverStart={() => animateControls?.pause()} style={{
          x: scrollX
        }}>
                            {topGirlsQuery.data?.map((girl, idx) => <Fragment key={`${idx}_${girl.id}`}>
                                    <Link href={`/${girl.username}`} className="relative aspect-[9/16] min-w-52 overflow-hidden">
                                        <img src={replaceApiUrl(girl.image!) ?? `https://api.dicebear.com/9.x/thumbs/svg?seed=${girl.username}`} alt={girl.username} className="h-full w-full object-cover" />
                                        <span className="absolute inset-0 bg-gradient-to-t from-black/95 to-transparent" />
                                        <div className="absolute inset-x-0 bottom-0 z-20 px-5 py-3">
                                            <h2 className="text-2xl font-black tracking-widest text-white">{girl.firstName ? girl.firstName : 'Somebody'}</h2>
                                            <span className="text-sm text-rose-400">@{girl.username}</span>
                                        </div>
                                    </Link>
                                </Fragment>)}
                        </motion.div>
                    </div>
                </MaxWidthWrapper>}
        </div>;
};
export default TopGirlsSection;