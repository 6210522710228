'use client';

import { MotionProps } from 'framer-motion';
import * as React from 'react';
import { SVGProps } from 'react';
import { motion } from 'framer-motion';
type Props = SVGProps<SVGSVGElement> & MotionProps & {};
const AkGirlsCz: React.FC<Props> = props => <motion.svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: 2,
  height: props.height ?? 'fit-content',
  width: props.width ?? 'fit-content'
}} viewBox="0 0 271 36" {...props} data-sentry-element="unknown" data-sentry-component="AkGirlsCz" data-sentry-source-file="AkgirlsCz.tsx">
        <path d="M.425 0H.346L.283-.482.22 0H.141l.106-.7h.039c.03 0 .035.019.038.038L.425 0Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 -7.05 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.448 0 .327-.383.44-.698H.365l-.088.253V-.7H.229c-.008.001-.023.005-.023.027V0h.071v-.313L.373 0h.075Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 20.95 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.41-.618a.09.09 0 0 0-.087-.089H.279a.09.09 0 0 0-.089.089v.536c0 .049.04.089.089.089h.044A.09.09 0 0 0 .41-.082v-.255c0-.027-.013-.039-.035-.039h-.09l.024.05s.01.023.026.023h.003v.209a.038.038 0 1 1-.076 0v-.512a.038.038 0 1 1 .076 0v.16A.793.793 0 0 0 .39-.47C.4-.475.41-.485.41-.509v-.109Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 50.95 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.214-.7v.685C.215-.01.218 0 .235 0h.051v-.672A.027.027 0 0 0 .257-.7H.214Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 80.95 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.408 0v-.268S.41-.32.382-.334c.029-.007.026-.062.026-.062v-.216A.088.088 0 0 0 .32-.7H.189V0h.072v-.301h.038c.019 0 .037.011.037.045V0h.072ZM.261-.369v-.264h.038c.019 0 .037.01.037.036v.188c0 .032-.018.04-.037.04H.261Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 105.95 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.283-.071v-.607C.283-.69.273-.699.261-.7h-.05V0h.172c.023 0 .034-.01.039-.019l.023-.052H.283Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 135.95 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.391-.548c.009-.005.02-.016.02-.039v-.027c0-.048-.039-.092-.087-.093H.279c-.05 0-.09.044-.09.093v.072s-.003.047.032.111l.086.161s.031.054.031.095v.08a.038.038 0 1 1-.076.001v-.122l-.053.023c-.009.005-.02.016-.02.039v.072a.09.09 0 0 0 .087.089h.045c.05 0 .09-.04.09-.089v-.089S.414-.218.379-.282L.293-.444S.262-.497.262-.538v-.067c0-.022.017-.039.038-.039.021 0 .038.017.038.039v.081l.053-.024Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 163.45 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.236 0v-.077s0-.011-.012-.011h-.06v.068c.001.011.01.02.021.02h.051Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 193.45 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.412-.251.36-.228c-.009.005-.019.016-.019.039v.094a.038.038 0 0 1-.039.039.038.038 0 0 1-.038-.039v-.511c0-.021.017-.038.038-.038.022 0 .039.017.039.038v.157l.052-.024c.009-.004.019-.015.019-.038v-.107a.089.089 0 0 0-.086-.089H.282a.09.09 0 0 0-.09.089v.536c0 .049.04.089.09.089h.044a.09.09 0 0 0 .086-.089v-.169Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 218.45 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
        <path d="M.449-.7H.177L.2-.648c.005.009.016.02.039.02H.32c.012 0 .016.008.014.017L.128 0h.261c.023 0 .033-.01.038-.02L.45-.071H.255c-.01 0-.016-.007-.013-.019L.449-.7Z" style={{
    fillRule: 'nonzero'
  }} transform="matrix(50 0 0 50 248.45 35.35)" data-sentry-element="path" data-sentry-source-file="AkgirlsCz.tsx" />
    </motion.svg>;
export default AkGirlsCz;